export default [
  {
    name: "smokeTextureOne",
    type: "texture",
    path: "/txt1.jpg",
  },
  {
    name: "smokeTextureTwo",
    type: "texture",
    path: "/txt2.jpg",
  },
];
